export const inputSelectData = [
  {
    name: "person-type",
    title: "Client type",
    comment: null,
    selects: [
      {
        id: "physical",
        label: "Physical person",
        selected: true,
      },
      {
        id: "juridical",
        label: "Juridical person",
      },
    ],
  },
];
