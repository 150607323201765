import { Card, Tabs, Text, Title } from "@mantine/core";
import { useNavigate, useParams } from "react-router-dom";
import { ChevronRight } from "tabler-icons-react";
const TemplateDocumentScreen = () => {
  const navigate = useNavigate();
  const { tabValue } = useParams();
  return (
    <Tabs
      value={tabValue}
      onTabChange={(value) => navigate(`/template-docs/${value}`)}
    >
      <Tabs.List grow>
        <Tabs.Tab value="templates">Templates</Tabs.Tab>
        <Tabs.Tab value="second">Create from template</Tabs.Tab>
        <Tabs.Tab value="third">Third tab</Tabs.Tab>
      </Tabs.List>
      <Tabs.Panel key="1" value="templates" style={{ padding: 10 }}>
        <Card
          withBorder
          shadow="sm"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            <Title order={4}>Piedāvājums</Title>
            <Text color="dimmed" size="xs">
              Vārds, Uzvārds, patēriņš (m2 * kwh)
            </Text>
          </div>
          <div>
            <ChevronRight size={60} strokeWidth={1.5} />
          </div>
        </Card>
      </Tabs.Panel>
    </Tabs>
  );
};

export default TemplateDocumentScreen;
