const accessRights = {
  0: {
    // Admin
    Admin: { view: true, insert: true, edit: true, delete: true },
    adminTasks: { view: true, insert: true, edit: true, delete: true },
    adminUsers: { view: true, insert: true, edit: true, delete: true },
    adminCalls: { view: true, insert: true, edit: true, delete: true },
    adminClients: { view: true, insert: true, edit: true, delete: true },
    partner: { view: true, insert: true, edit: true, delete: true },
    invoices: { view: true, insert: true, edit: true, delete: true },
    markInvoices: { view: true, insert: true, edit: true, delete: true },
    tasks: { view: true, insert: true, edit: true, delete: true },
    leads: { view: false },
    reports: {view: true, insert: true, edit: true, delete: true},
    imports: {view: true, insert: true, edit: true, delete: true}
    // Additional pages...
  },
  3: {
    // Supervisor 3
    Admin: { view: true, insert: true, edit: true, delete: true },
    adminTasks: { view: true, insert: true, edit: true, delete: true },
    adminUsers: { view: true, insert: true, edit: true, delete: true },
    adminCalls: { view: false, insert: true, edit: true, delete: true },
    adminClients: { view: true, insert: true, edit: true, delete: true },
    partner: { view: true, insert: true, edit: true, delete: true },
    invoices: { view: true, insert: true, edit: true, delete: true },
    markInvoices: { view: true, insert: true, edit: false, delete: true },
    tasks: { view: true, insert: true, edit: true, delete: true },
    leads: { view: false },
    // Additional pages...
  },
  10: {
    // User
    Admin: { view: false, insert: false, edit: false, delete: false },
    adminTasks: { view: false, insert: false, edit: false, delete: false },
    adminUsers: { view: false, insert: false, edit: false, delete: false },
    adminCalls: { view: false, insert: false, edit: false, delete: false },
    adminClients: { view: false, insert: false, edit: false, delete: false },
    partner: { view: true, insert: true, edit: true, delete: true },
    invoices: { view: true, insert: true, edit: true, delete: true },
    markInvoices: { view: false, insert: false, edit: false, delete: false },
    tasks: { view: true, insert: true, edit: true, delete: true },
    leads: { view: false },
  },
  20: {
    leads: { view: true },
  },
  // Additional groups...
};

function hasAccess(group, page, right = null) {
  // Get the access rights for the group
  const groupRights = accessRights[group];

  // If the group doesn't have any rights, it can't access the page
  if (!groupRights) {
    return false;
  }

  // Get the rights for the page
  const pageRights = groupRights[page];

  // If the group doesn't have any rights for the page, it can't access the page
  if (!pageRights) {
    return false;
  }

  // If no specific right is given, check for view access
  if (right === null) {
    return pageRights.view || false;
  }

  // If specific rights are given, check each of them and return the ones that are true
  if (Array.isArray(right)) {
    return right.filter((r) => pageRights[r] || false);
  }

  // For single right
  return pageRights[right] ? pageRights[right] : false;
}

export default hasAccess;
