import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  LoadingOverlay,
  MultiSelect,
  TextInput,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import {
  getEmailAccount,
  getEmailAccounts,
  getReportFilters,
  saveEmailAccount,
  updateEmailAccount,
} from "../../functions/api";
import { Pencil, Trash } from "tabler-icons-react";
import { parseNumberStringToArray } from "../../functions/misc";

export const AdminEmailManagement = () => {
  const [emails, setEmails] = useState();
//   const [opened, { open, close }] = useDisclosure(false);

  const intl = useIntl();

  useEffect(() => {
    getEmailAccounts().then((x) => setEmails(x));
  }, []);

  return (
    <>
      <Button
        variant="contained"
        style={{ marginTop: 10, backgroundColor: "dodgerblue", color: "white" }}
        color="primary"
        onClick={() => (window.location.href = "/admin/emails/new")}
      >
        <FormattedMessage id="add_email" />
      </Button>
      {emails &&
        emails?.map((mail) => (
          <Card withBorder mt={5}>
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              key={mail.email_id}
            >
              <div>{mail.username}</div>
              <div>
                {mail.host} : {mail.port}
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <a href={`#`} style={{ marginRight: 10, padding: 3 }}>
                  <Trash color="red" />
                </a>
                <a
                  style={{ padding: 3 }}
                  href={`/admin/emails/${mail.email_id}`}
                >
                  <Pencil />
                </a>
              </div>
            </div>
          </Card>
        ))}
    </>
  );
};

export const AdminManageEmail = () => {
  const intl = useIntl();
  const [filters, setFilters] = useState();
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState();

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id != "new") {
      getEmailAccount(id).then((x) =>
        setValues({
          email_id: x.email_id,
          user: x.username,
          port: x.port,
          host: x.host,
          read: parseNumberStringToArray(x.can_read),
          send: parseNumberStringToArray(x.can_send),
          all_send: x.send_all,
          all_read: x.read_all,
        })
      );
    }
    getReportFilters().then((x) => {
      setFilters(x);
      setLoading(false);
    });
  }, []);

  const handleChange = (name) => (value) => {
    setValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const submitEmailAccount = async () => {
    if (id != "new") {
      updateEmailAccount(values);
      window.location.reload();
    } else {
      const emailAccount = await saveEmailAccount(values);
      if (emailAccount.win) {
        window.location.href = "/admin/emails/" + emailAccount.win;
      }
    }
  };

  return (
    <>
      {loading ? (
        <>
          <Box style={{ height: 700, width: "100%" }} pos="relative">
            <LoadingOverlay visible={loading} overlayBlur={2} />
          </Box>
        </>
      ) : (
        <div>
          <Card withBorder style={{ margin: 10 }}>
            <div
              style={{
                fontSize: 24,
                fontWeight: 800,
                marginBottom: 10,
              }}
            >
              <FormattedMessage id="email_account_management" />
            </div>
            <div>
              <Grid>
                <Grid.Col xs={6}>
                  <TextInput
                    placeholder="this@that.com"
                    label="Username"
                    withAsterisk
                    value={values?.user}
                    onChange={(t) => handleChange("user")(t.target.value)}
                  />
                </Grid.Col>
                <Grid.Col xs={6}>
                  <TextInput
                    placeholder="12345678"
                    label="Password"
                    withAsterisk
                    value={values?.password}
                    onChange={(t) => handleChange("password")(t.target.value)}
                  />
                </Grid.Col>
                <Grid.Col xs={6}>
                  <TextInput
                    placeholder="mai.that.com"
                    label="Host"
                    value={values?.host}
                    onChange={(t) => handleChange("host")(t.target.value)}
                  />
                </Grid.Col>
                <Grid.Col xs={6}>
                  <TextInput
                    placeholder="993"
                    label="Port"
                    type="numer"
                    value={values?.port}
                    onChange={(t) => handleChange("port")(t.target.value)}
                  />
                </Grid.Col>
                {id == "new" && (
                  <Grid.Col xs={2}>
                    <Button
                      fullWidth
                      onClick={() => submitEmailAccount()}
                      style={{ backgroundColor: "dodgerblue", marginTop: 10 }}
                    >
                      <FormattedMessage id="sign_in" />
                    </Button>
                  </Grid.Col>
                )}
                {id != "new" && (
                  <>
                    <Grid.Col>
                      <Divider
                        my="xs"
                        label={<FormattedMessage id="email_rights" />}
                        labelPosition="center"
                      />
                    </Grid.Col>
                    <Grid.Col xs={6}>
                      <div>
                        <div className="relative flex gap-x-3">
                          <div className="flex h-6 items-center">
                            <input
                              id="all_read"
                              name="all_read"
                              type="checkbox"
                              checked={values && values.all_read}
                              onChange={(event) =>
                                handleChange("all_read")(
                                  event.currentTarget.checked
                                )
                              }
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                          </div>
                          <div className="text-sm leading-6">
                            <label
                              htmlFor="comments"
                              className="font-medium text-gray-900"
                            >
                              Allow all to read
                            </label>
                          </div>
                        </div>
                      </div>
                      <MultiSelect
                        disabled={values?.all_read}
                        data={filters?.users}
                        label={intl.formatMessage({ id: "allow_read" })}
                        placeholder={intl.formatMessage({
                          id: "select_status",
                        })}
                        searchable
                        clearable
                        multiple
                        value={values?.read}
                        onChange={handleChange("read")}
                      />
                    </Grid.Col>
                    <Grid.Col xs={6}>
                      <div>
                        <div className="relative flex gap-x-3">
                          <div className="flex h-6 items-center">
                            <input
                              id="all_send"
                              name="all_send"
                              type="checkbox"
                              checked={values && values.all_send}
                              onChange={(event) =>
                                handleChange("all_send")(
                                  event.currentTarget.checked
                                )
                              }
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                          </div>
                          <div className="text-sm leading-6">
                            <label
                              htmlFor="comments"
                              className="font-medium text-gray-900"
                            >
                              Allow all to send
                            </label>
                          </div>
                        </div>
                      </div>
                      <MultiSelect
                        disabled={values?.all_send}
                        data={filters?.users}
                        label={intl.formatMessage({ id: "allow_send" })}
                        placeholder={intl.formatMessage({
                          id: "select_status",
                        })}
                        searchable
                        clearable
                        multiple
                        value={values?.send}
                        onChange={handleChange("send")}
                      />
                    </Grid.Col>
                    {id != "new" && (
                      <Grid.Col xs={2}>
                        <Button
                          onClick={() => submitEmailAccount()}
                          fullWidth
                          style={{
                            backgroundColor: "dodgerblue",
                            marginTop: 10,
                          }}
                        >
                          <FormattedMessage id="save" />
                        </Button>
                      </Grid.Col>
                    )}
                  </>
                )}
              </Grid>
            </div>
          </Card>
        </div>
      )}
    </>
  );
};

const ReplyToEmailScreen = () => {
  return <></>;
};
