// import { StatsGrid } from "../Components/StatsGrid";
import SearchBox from "../../Components/SearchBox";
import { useCallback, useEffect, useState } from "react";
import {
  getTasks,
  getPinned,
  getMissedInvoices,
  getSystemNotif,
  getHistory,
} from "../../functions/api";
import TaskList from "../../Components/TaskLine";
import { PinBar } from "../../Components/PinComponents";
import { Alert, Card } from "@mantine/core";
import InvoiceCard from "../../Components/InvoiceCard";
import { AlertCircle } from "tabler-icons-react";
import TaskMap from "../../Components/TaskMap";
import { FormattedMessage, useIntl } from "react-intl";
import OverlayCombined, {
  handleAction,
} from "../../Components/Overlays/OverlayCombined";
import { getGroup } from "../../functions/tokens";
import HistoryCard from "../../Components/HistoryCard";

export const additionalDisplay = (displayMore) => {
  return {
    showCheckbox: displayMore,
    showAsignedTo: displayMore,
    showCreatedBy: displayMore,
    showCreatedOn: displayMore,
    showDeadline: displayMore,
  };
};

export default function HomeScreen() {
  const intl = useIntl();
  const [sidemodal, setSidemodal] = useState(false);
  const [modalData, setModaldata] = useState();

  const [upcomingTasks, setUpcomingTasks] = useState([]);
  const [missedTasks, setMissedTasks] = useState([]);
  const [overlay, setOverlay] = useState(false);
  const [ovData, setOvdata] = useState();
  const [pinned, setPinned] = useState();
  const [invoices, setInvoices] = useState();
  const [sysnotif, setSysnotif] = useState();

  const [history, setHistory] = useState(null);
  const [timer, setTimer] = useState(0);

  const displayMore = true;

  const taskOverlay = useCallback((data) => {
    setOvdata(data);
    setOverlay(true);
  }, [ovData]);

  const callOverlay = useCallback((i, type) => {
    const that = (data) => taskOverlay(data);

    setModaldata(handleAction(i, type, that));
    setSidemodal(true);
  }, [ovData]);

  useEffect(() => {
    getHistory(history ? history[0].hid : null).then((newHistory) => {
      if (newHistory && newHistory.length > 0) {
        setHistory(
          history
            ? (prevHistory) => [...newHistory, ...prevHistory]
            : newHistory
        );
      }
    });
    console.log(timer);
  }, [timer]);

  useEffect(() => {
    if (getGroup() == 0) {
      const interval = setInterval(() => setTimer(timer + 1), 10000);
      return () => clearInterval(interval);
    }
  });

  useEffect(() => {
    const loadScreen = () => {
      const now = new Date();
      getTasks().then((tasks) => {
        const upcomingTasks =
          tasks.length > 0 &&
          tasks.filter((task) => new Date(task.deadline) >= now);
        const missedTasks =
          tasks.length > 0 &&
          tasks.filter((task) => new Date(task.deadline) < now);
        setUpcomingTasks(upcomingTasks);
        setMissedTasks(missedTasks);
      });
      getPinned().then((pins) => {
        setPinned(pins);
      });
      getMissedInvoices().then((inv) => setInvoices(inv));
    };

    loadScreen();

    const interval = setInterval(() => {
      loadScreen();
    }, 60000);

    return () => clearInterval(interval);
  }, [sidemodal, overlay, ovData, modalData]);

  useEffect(() => {
    setTimer(1);
    getSystemNotif().then((x) => {
      setSysnotif(x);
    });
  }, []);

  return (
    <>
      <div style={{ maxHeight: 200, overflow: "auto" }}>
        {sysnotif &&
          sysnotif.length > 0 &&
          sysnotif.map((n) => (
            <Alert
              key={n.sn_id}
              icon={<AlertCircle size="2rem" />}
              title={n.title}
              color={n.color ? n.color : "green"}
              style={{ margin: 10, marginBottom: 5 }}
            >
              {n.text}
            </Alert>
          ))}
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        <OverlayCombined
          sidemodal={sidemodal}
          displayActions={false}
          setSidemodal={setSidemodal}
          modalData={modalData}
          setModaldata={setModaldata}
          overlay={overlay}
          setOverlay={setOverlay}
          ovData={ovData}
          setOvdata={setOvdata}
        />

        <div
          style={{
            //flex: "100%",
            width: "100%",
            display: "flex",
            // padding: 10,
            padding: 5,
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "100%" }}>
            <div style={{ width: "100%", maxWidth: 500 }}>
              <SearchBox onClick={(data) => callOverlay(data, "task")} />
            </div>
          </div>
          {/* <Switch label="Rādīt klientu statusus" checked={showStates} onChange={(event) => setShowStates(event.currentTarget.checked)} /> */}
        </div>

        {pinned && pinned.length > 0 && <PinBar pinned={pinned} />}
        {(upcomingTasks.length > 0 || missedTasks.length > 0) && (
          <TaskMap
            tasks={[...upcomingTasks, ...missedTasks]}
            callBack={(data) => callOverlay(data, "task")}
          />
        )}
        {upcomingTasks.length > 0 && (
          <TaskList
            title={intl.formatMessage({ id: "upcoming_tasks" })}
            tasks={upcomingTasks}
            displayData={additionalDisplay(displayMore)}
            onClick={(data) => callOverlay(data, "task")}
          />
        )}

        {missedTasks.length > 0 && (
          <TaskList
            title={intl.formatMessage({ id: "delayed_tasks" })}
            tasks={missedTasks}
            displayData={additionalDisplay(displayMore)}
            onClick={(data) => callOverlay(data, "task")}
          />
        )}
        <div
          className="px-4 py-6 sm:px-0"
          style={{ flex: 4, flexBasis: 300, maxWidth: 400 }}
        >
          {getGroup() == 0 && history && history.length > 0 && (
            <div>
              <span style={{ fontSize: 15, fontWeight: "bold" }}>
                <FormattedMessage id="activity" />
                <Card
                  withBorder
                  radius="md"
                  style={{
                    maxHeight: 400,
                    overflow: "hidden",
                    overflowY: "auto",
                    padding: 5,
                    margin: 5,
                  }}
                >
                  {history &&
                    history.map((his) => (
                      <HistoryCard key={his.hid} data={his} />
                    ))}
                </Card>
              </span>
            </div>
          )}
          {invoices && invoices.length > 0 && (
            <div style={{ marginTop: 20 }}>
              <span style={{ fontSize: 15, fontWeight: "bold" }}>
                <FormattedMessage id="missed_invoices" />
              </span>
              <Card
                withBorder
                radius="md"
                style={{
                  maxHeight: 400,
                  overflow: "hidden",
                  overflowY: "auto",
                  padding: 5,
                  margin: 5,
                }}
              >
                {invoices &&
                  invoices.map((inv) => (
                    <InvoiceCard key={inv.invoice_id} invoice={inv} />
                  ))}
              </Card>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
