import { useEffect, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  ColorInput,
  Input,
  TextInput,
} from "@mantine/core";
import { getClientSource, saveNewSource } from "../../functions/api";
import { AlertCircle } from "tabler-icons-react";
import { SourceBadge } from "../../Components/Inputs/EditableMulti";
import { FormattedMessage, useIntl } from 'react-intl';

const AddSourceScreen = () => {
  const intl = useIntl();
  const [existing, setExisting] = useState("");
  const [show, setShow] = useState("");
  const [textCol, setTextCol] = useState("#ffffff");
  const [bgCol, setGbCol] = useState("#3471eb");
  const [name, setName] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (name.length > 2) {
      getClientSource(name).then((a) => setExisting(a));
      setSuccess(false);
    } else {
      setExisting(null);
    }
  }, [name]);

  const saveSource = () => {
    saveNewSource({ name: name, bgclr: bgCol, text: textCol }).then((x) => {
      console.log(x);
      if (x[0].source_id) {
        setSuccess(true);
        setExisting(null);
        setShow("");
        setTextCol("#ffffff");
        setGbCol("#3471eb");
        setName("");
      }
    });
  };

  return (
    <>
      <div>
        {success && (
          <Alert
            icon={<AlertCircle size="2rem" />}
            title={intl.formatMessage({id: "soon"})}
            color="green"
            style={{ marginBottom: 15 }}
          >
            <FormattedMessage id="source_created_success" />
          </Alert>
        )}
        <div>
          <TextInput
            placeholder={intl.formatMessage({id: "enter_new_source"})}
            value={name}
            onChange={(e) => setName(e.target.value)}
            label={intl.formatMessage({id: "source_name"})}
          />
          {existing && intl.formatMessage({id: "existing_similar_sources"})}
          {existing && (
            <div style={{ maxHeight: 75, overflow: "auto" }}>
              {existing &&
                existing.map((x) => (
                  <Badge
                    style={{
                      backgroundColor: x.source_color,
                      color: x.source_text_color,
                      margin: 3,
                    }}
                  >
                    {x.source_name}
                  </Badge>
                ))}
            </div>
          )}
        </div>
        <Button
          style={{
            backgroundColor: "limegreen",
            marginBottom: 15,
            marginTop: 15,
          }}
          onClick={() => (show == 1 ? setShow(null) : setShow(1))}
        >
          <FormattedMessage id="pick_bg_colour" />
        </Button>
      </div>
      {show == 1 && <ColorInput value={bgCol} onChange={setGbCol} />}
      <div>
        <Button
          style={{
            backgroundColor: "limegreen",
            marginBottom: 15,
            marginTop: 15,
          }}
          onClick={() => (show == 2 ? setShow(null) : setShow(2))}
        >
          <FormattedMessage id="pick_text_colour" />
        </Button>
      </div>
      {show == 2 && <ColorInput value={textCol} onChange={setTextCol} />}
      <div style={{ textAlign: "right" }}>
        <Button
          style={{
            backgroundColor: "dodgerblue",
            marginBottom: 15,
            marginTop: 15,
          }}
          onClick={saveSource}
        >
          <FormattedMessage id="save" />
        </Button>
        <hr />
        {name && (
          <>
        <div style={{textAlign: "center"}}>
        <FormattedMessage id="preview" />
          <div>
           <SourceBadge cs={{color: bgCol, textc: textCol, label: name}} />
         </div>
         </div>
          </>
        )}
      </div>
    </>
  );
};

export default AddSourceScreen;
