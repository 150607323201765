import React, { useEffect, useState } from "react";
import { getRightsGroups } from "../../functions/api";
import { Paper, Col, TextInput, Button, Select } from "@mantine/core";
import { useLocation } from "react-router-dom";
import { Container, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { FormattedMessage, useIntl } from 'react-intl';

const AdminRights = () => {
  const [groups, setGroups] = useState();
  useEffect(() => {
    getRightsGroups().then((data) => setGroups(data));
  }, []);
  return (
    <>
      <Button style={{ backgroundColor: "dodgerblue" }}>
        <FormattedMessage id="create_new_group" />
      </Button>
    </>
  );
};

const resources = [
  "Admin",
  "adminTasks",
  "adminUsers",
  "adminCalls",
  "adminClients",
  "partner",
  "invoices",
  "markInvoices",
  "tasks",
  "leads",
  "reports",
  "imports",
];
const defaultRights = resources.reduce(
  (acc, curr) => ({
    ...acc,
    [curr]: { view: false, insert: false, edit: false, delete: false },
  }),
  {}
);

const selectOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

export const RightsGroupForm = () => {
  const intl = useIntl();
  const [groupKey, setGroupKey] = useState(null);
  const [groupData, setGroupData] = useState({});
  const location = useLocation();
  let routes = location.pathname.split("/");

  useEffect(() => {
    if (routes[3] !== "new") {
      setGroupKey(routes[3]);
      // fetch and set the group data from the server, using the groupKey
      // for the sake of example, we'll just set some dummy data
      setGroupData({
        group_id: 3,
        group_name: <FormattedMessage id="example_group" />,
        group_description: <FormattedMessage id="example_group_descr" />,
        rights: defaultRights,
      });
    } else {
      setGroupData({
        group_id: null,
        group_name: "",
        group_description: "",
        rights: defaultRights,
      });
    }
  }, [routes]);

  const form = useForm({
    initialValues: groupData,
  });

  const handleSubmit = () => {
    // handle form submission here
    console.log(form.values);
  };

  return (
    <Container>
      <Paper padding="md" shadow="xs">
        <Text size="xl" weight={500}>
          {groupKey ? intl.formatMessage({id: "edit_rights_group"}) : intl.formatMessage({id: "create_rights_group"})}
        </Text>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            {...form.register("group_name")}
            label={intl.formatMessage({ id: "group_name" })}
            placeholder={intl.formatMessage({ id: "enter_group_name" })}
            required
          />
          <TextInput
            {...form.register("group_description")}
            label={intl.formatMessage({ id: "group_descr" })}
            placeholder={intl.formatMessage({ id: "enter_group_descr" })}
            multiline
            required
          />
          {resources.map((resource) => (
            <Col key={resource} span={12} style={{ padding: "1rem 0" }}>
              <Text size="md" weight={500}>
                {resource}
              </Text>
              {[intl.formatMessage({ id: "view" }),
              intl.formatMessage({ id: "insert" }),
              intl.formatMessage({ id: "edit" }),
              intl.formatMessage({ id: "delete" })].map((action) => (
                <Select
                  {...form.register(`rights.${resource}.${action}`)}
                  data={selectOptions}
                  label={`${action.charAt(0).toUpperCase() + action.slice(1)
                    } Permission`}
                  required
                />
              ))}
            </Col>
          ))}
          <Button submit type="submit" color="blue" size="lg">
            <FormattedMessage id="submit" />
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default AdminRights;
