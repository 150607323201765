import React, { useState, useEffect } from "react";
import { AppShell, Navbar, Header, Title } from "@mantine/core";
import { MegaMenu } from "primereact/megamenu";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Grid, Card, UnstyledButton, Group, Avatar, Text } from "@mantine/core";
import { EditableLabels } from "../../Components/Inputs/EditableLabels";
import { FormattedMessage, useIntl } from 'react-intl';

const BussinessScreen = () => {
  const intl = useIntl();
  const items = [
    {
      label: intl.formatMessage({id: "process"}),
      icon: "pi pi-fw pi-pencil",
      items: [
        [
          {
            items: [
              { label: intl.formatMessage({id: "change_client"}) },
              { label: intl.formatMessage({id: "close_client"}) },
            ],
          },
        ],
      ],
    },
    {
      label: intl.formatMessage({id: "workers"}),
      icon: "pi pi-fw pi-users",
      items: [
        [
          {
            label: intl.formatMessage({id: "user1"}),
            items: [{ label: intl.formatMessage({id: "user1p1"}) }, { label: intl.formatMessage({id: "user1p2"}) }],
          },
          {
            label: intl.formatMessage({id: "user2"}),
            items: [{ label: intl.formatMessage({id: "user2p1"}) }, { label: intl.formatMessage({id: "user2p2"}) }],
          },
        ],
        [
          {
            label: intl.formatMessage({id: "user3"}),
            items: [{ label: intl.formatMessage({id: "user3p1"}) }, { label: intl.formatMessage({id: "user3p2"}) }],
          },
          {
            label: intl.formatMessage({id: "user4"}),
            items: [{ label: intl.formatMessage({id: "user4p1"}) }, { label: intl.formatMessage({id: "user4p2"}) }],
          },
        ],
        [
          {
            label: intl.formatMessage({id: "user5"}),
            items: [{ label: intl.formatMessage({id: "user5p1"}) }, { label: intl.formatMessage({id: "user5p2"}) }],
          },
          {
            label: intl.formatMessage({id: "user6"}),
            items: [{ label: intl.formatMessage({id: "user6p1"}) }, { label: intl.formatMessage({id: "user6p2"}) }],
          },
        ],
      ],
    },
    {
      label: intl.formatMessage({id: "events"}),
      icon: "pi pi-fw pi-calendar",
      items: [
        [
          {
            label: intl.formatMessage({id: "event1"}),
            items: [{ label: intl.formatMessage({id: "event1p1"}) }, { label: intl.formatMessage({id: "event1p2"}) }],
          },
          {
            label: intl.formatMessage({id: "event2"}),
            items: [{ label: intl.formatMessage({id: "event2p1"}) }, { label: intl.formatMessage({id: "event2p2"}) }],
          },
        ],
        [
          {
            label: intl.formatMessage({id: "event3"}),
            items: [{ label: intl.formatMessage({id: "event3p1"}) }, { label: intl.formatMessage({id: "event3p2"}) }],
          },
          {
            label: intl.formatMessage({id: "event4"}),
            items: [{ label: intl.formatMessage({id: "event4p1"}) }, { label: intl.formatMessage({id: "event4p2"}) }],
          },
        ],
      ],
    },
    {
      label: intl.formatMessage({id: "settings"}),
      icon: "pi pi-fw pi-cog",
      items: [
        [
          {
            label: intl.formatMessage({id: "setting1"}),
            items: [{ label: intl.formatMessage({id: "setting1p1"}) }, { label: intl.formatMessage({id: "setting1p2"}) }],
          },
          {
            label: intl.formatMessage({id: "setting2"}),
            items: [{ label: intl.formatMessage({id: "setting2p1"}) }, { label: intl.formatMessage({id: "setting2p2"}) }],
          },
          {
            label:intl.formatMessage({id: "setting3"}),
            items: [{ label: intl.formatMessage({id: "setting3p1"}) }, { label: intl.formatMessage({id: "setting3p2"}) }],
          },
        ],
        [
          {
            label: intl.formatMessage({id: "setting4"}),
            items: [{ label: intl.formatMessage({id: "setting4p1"}) }, { label: intl.formatMessage({id: "setting4p2"}) }],
          },
        ],
      ],
    },
  ];

  const darbinieki = [
    {
      c_name: "Jānis",
      c_surname: "Pētersons",
      c_phone: "+37120040000",
      c_email: "ee@gmail.lv",
    },
    {
      c_name: "Bobs",
      c_surname: "Bilders", // lol
      c_phone: "+37120040000",
      c_email: "",
    },
  ];

  function getInitials(c_name, c_surname) {
    let initials = "";

    if (c_name && c_surname) {
      // Both c_name and c_surname are not empty
      initials = c_name.charAt(0) + c_surname.charAt(0);
    } else if (c_name) {
      // Only c_name is not empty
      initials = c_name.substring(0, 2);
    } else if (c_surname) {
      // Only c_surname is not empty
      initials = c_surname.substring(0, 2);
    }

    return initials;
  }

  const edit = false;
  return (
    <div style={{ padding: 5 }}>
      <div className="card">
        <MegaMenu model={items} breakpoint="960px" />
      </div>
      <Grid style={{ paddingTop: 20 }}>
        <Grid.Col xs={4}>
          <Card
            withBorder
            radius="md"
            style={{ maxHeight: 600, overflow: "auto" }}
          >
            <Title order={4}><FormattedMessage id="business_info" /></Title>
            <EditableLabels
              edit={edit}
              label={intl.formatMessage({id: "legal_name"})}
              placeholder={intl.formatMessage({id: "legal_name_ph"})}
              data={"SIA THIS"}
              withAsterisk={true}
              onInputChange={(val) => console.log(val)}
            />
            <EditableLabels
              edit={edit}
              label={intl.formatMessage({id: "reg_code"})}
              placeholder={intl.formatMessage({id: "reg_code_ph"})}
              data={"LV102341214"}
              withAsterisk={true}
              onInputChange={(val) => console.log(val)}
            />
            <EditableLabels
              edit={edit}
              label={intl.formatMessage({id: "vat"})}
              placeholder={intl.formatMessage({id: "vat_ph"})}
              data={"321102341214"}
              withAsterisk={true}
              onInputChange={(val) => console.log(val)}
            />
            <EditableLabels
              edit={edit}
              label={intl.formatMessage({id: "jur_address"})}
              placeholder={intl.formatMessage({id: "jur_address_ph"})}
              data={"Rīga, Rīgas iela 52"}
              withAsterisk={true}
              onInputChange={(val) => console.log(val)}
            />
            <EditableLabels
              edit={edit}
              label={intl.formatMessage({id: "fact_address"})}
              placeholder={intl.formatMessage({id: "fact_address_ph"})}
              data={"Rīga, Rīgas iela 52"}
              withAsterisk={true}
              onInputChange={(val) => console.log(val)}
            />
            <EditableLabels
              edit={edit}
              label={intl.formatMessage({id: "description"})}
              placeholder={intl.formatMessage({id: "description_ph"})}
              data={
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. "
              }
              withAsterisk={true}
              onInputChange={(val) => console.log(val)}
            />
          </Card>
        </Grid.Col>
        <Grid.Col xs={4} style={{ maxHeight: 600, overflow: "auto" }}>
          <Card withBorder radius="md">
            <Title order={4}><FormattedMessage id="workers" /></Title>
            {darbinieki &&
              darbinieki.map((d) => (
                <UnstyledButton>
                  <Group>
                    <Avatar size={40} color="blue">
                      {getInitials(d.c_name, d.c_surname)}
                    </Avatar>
                    <div>
                      <Text>
                        {d.c_name} {d.c_surname}
                      </Text>
                      <Text size="xs" color="dimmed">
                        {d.c_email ? d.c_email : d.c_phone ? d.c_phone : ""}
                      </Text>
                    </div>
                  </Group>
                </UnstyledButton>
              ))}
          </Card>
        </Grid.Col>
        <Grid.Col xs={4} style={{ maxHeight: 600, overflow: "auto" }}>
          <Card withBorder radius="md">
            <Title order={4}><FormattedMessage id="tasks" /></Title>
          </Card>
        </Grid.Col>
        <Grid.Col xs={8}>
          <Card withBorder radius="md">
            <Title order={4}><FormattedMessage id="workers" /></Title>
          </Card>
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default BussinessScreen;
