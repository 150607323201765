import { Card } from "@mantine/core";
import { TimeDiffToDisplay } from "../functions/misc";
import { FormattedMessage } from "react-intl";
import {
  Checklist,
  DiscountCheck,
  FileCheck,
  FileEuro,
  FileInvoice,
  FileMinus,
  Hammer,
  HammerOff,
  User,
  UserMinus,
  UserPlus,
} from "tabler-icons-react";

const HistoryCard = ({ data }) => {
  const actionTime = new Date(data.action_time);
  const localActionTime = new Date(
    actionTime.getTime() - actionTime.getTimezoneOffset() * 60000
  );

  const timeDiff = new Date() - localActionTime;

  const getAction = () => {
    if (data.action.includes("invoice")) {
      return (
        <span key={data.hid}>
          <FormattedMessage id={data.action.replace(/-/g, "_")} />{" "}
          <a
            style={{ color: "blue" }}
            target="_blank"
            href={"/invoices/" + data.invoiceid}
          >
            {data.invoice_name}
          </a>
          {(data.c_name || data.c_surname || data.c_email || data.c_phone) && (
            <>
              {" "}
              (
              <a
                style={{ color: "blue" }}
                href={"/partner/" + data.clientid}
                target="_blank"
              >
                {data.c_name || data.c_surname
                  ? (data.c_name ? data.c_name : "") +
                    " " +
                    (data.c_surname ? data.c_surname : "")
                  : data.c_email
                  ? data.c_email
                  : data.c_phone}
              </a>
              )
            </>
          )}
          {data.data && " (" + data.data + ")"}
        </span>
      );
    } else {
      return (
        <span>
          <FormattedMessage id={data.action.replace(/-/g, "_")} />{" "}
          <a
            style={{ color: "blue" }}
            href={"/partner/" + data.clientid}
            target="_blank"
          >
            {data.c_name || data.c_surname
              ? (data.c_name ? data.c_name : "") +
                " " +
                (data.c_surname ? data.c_surname : "")
              : data.c_email
              ? data.c_email
              : data.c_phone}
          </a>
          {data.data && " (" + data.data + ")"}
        </span>
      );
    }
  };

  return (
    <Card
      withBorder
      style={{
        padding: 10,
        marginBottom: 1,
        backgroundColor:
          data.action == "invoice-void" ||
          data.action == "task-close" ||
          data.action == "client-close"
            ? "#ff8c8c99"
            : data.action == "task-done" || data.action == "invoice-paid"
            ? "#8fff8c99"
            : "",
      }}
    >
      <span
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <span
          style={{
            fontSize: 12,
            fontWeight: "normal",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span>
            <span style={{ fontWeight: "bold" }}>
              {data.name || data.surname
                ? data.name + " " + data.surname
                : data.email}{" "}
            </span>
            {getAction()}
          </span>
          <span style={{ fontSize: 10, color: "grey" }}>
            {TimeDiffToDisplay(timeDiff)} <FormattedMessage id="ago" />
          </span>
        </span>
        <div>
          {data.action.includes("task") &&
            (data.action == "task-close" ? (
              <HammerOff size={20} />
            ) : data.action == "task-done" ? (
              <DiscountCheck size={20} />
            ) : (
              <Hammer size={20} />
            ))}
          {data.action.includes("client") &&
            (data.action == "client-create" ? (
              <UserPlus size={20} />
            ) : data.action == "client-close" ? (
              <UserMinus size={20} />
            ) : (
              <User size={20} />
            ))}

          {data.action.includes("invoice") &&
            (data.action == "invoice-create" ? (
              <FileEuro size={20} />
            ) : data.action == "invoice-void" ? (
              <FileMinus size={20} />
            ) : data.action == "invoice-paid" ? (
              <FileCheck size={20} />
            ) : (
              <FileInvoice size={20} />
            ))}
        </div>
      </span>
    </Card>
  );
};

export default HistoryCard;
