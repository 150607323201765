import "./App.css";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import LoginScreen, {
  PassResetScreen,
  ForgotPasswordScreen,
} from "./Screens/User/LoginScreen";
import HomeScreen from "./Screens/User/HomeScreen";
import MainContainer from "./Components/MainContainer";
import ProfileScreen from "./Screens/Client/ProfileScreen";
import PartnerScreen from "./Screens/Client/PartnerScreen";
import { useState, useEffect, useContext } from "react";
import {
  getGroup,
  getToken,
  getUserId,
  removeCallToken,
  removeData,
  removeGroup,
  removeToken,
  removeUserId,
} from "./functions/tokens";
import InvoicesPage from "./Screens/Client/InvoicesPage";
import AddInvoiceScreen from "./Screens/Client/AddInvoiceScreen";
import AdminScreen from "./Screens/Admin/AdminScreen";
import LeadScreen from "./Screens/User/LeadScreen";
import AdminTasks from "./Screens/Admin/AdminTasks";
import AddTaskScreen from "./Screens/Client/AddTaskScreen";
import AdminUsers from "./Screens/Admin/AdminUsers";
import InvoicePage from "./Screens/Client/InvoicePage";
import PDFscreen from "./Screens/Client/PDFscreen";
import AdminClientScreen from "./Screens/Admin/AdminClients";
import { IntlProvider } from "react-intl";
import { messages } from "./translations";
import { WebPhoneProvider, WebPhoneContext } from "./functions/WebPhoneContext";
import hasAccess from "./functions/access";
import AdminReports from "./Screens/Admin/AdminReports";
import ApiScreen from "./Screens/Admin/ApiScreen";
import { RightsGroupForm } from "./Screens/Admin/AdminRights";
import BussinessScreen from "./Screens/Client/BussinessScreen";
import ContractScreen from "./Screens/Client/ContractScreen";
import AdminUserScreen from "./Screens/Admin/AdminUserScreen";
import TemplateDocumentScreen from "./Screens/Client/TemplateDocumentScreen";
import { AdminManageEmail } from "./Screens/Admin/AdminEmailManagement";

function App() {
  const [isLoggedIn, setLoggedin] = useState(getToken());
  const navigation = useNavigate();
  const [locale, setLocale] = useState(
    localStorage.getItem("locale") || navigator.language || "en-GB"
  );
  const { resetPhone } = useContext(WebPhoneContext);

  useEffect(() => {
    setLoggedin(getToken());
  }, [navigation]);

  const Logout = () => {
    removeToken();
    removeGroup();
    removeData();
    removeUserId();
    removeCallToken();
    setLoggedin(false);
    resetPhone();
    window.location.reload();
    return <Navigate to="/login" />;
  };

  const RedirectWrapper = () => (
    <Navigate to={isLoggedIn ? "/main" : "/login"} />
  );

  const LanguageSwitch = () => {
    setLocale(locale == "en-GB" ? "lv-LV" : "en-GB");
    return <Navigate to={isLoggedIn ? "/main" : "/login"} />;
  };

  let group = getGroup();
  group = group == null ? 10 : group;
  // :)
  useEffect(() => {
    if (isLoggedIn) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCuqPajGGzPY2o6mjSkktTutium8pBdgPg&libraries=places`;
      script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [isLoggedIn]);

  return (
    <IntlProvider
      locale={locale}
      messages={messages[locale]}
      defaultLocale={"en-GB"}
    >
      <WebPhoneProvider
        useProvider={isLoggedIn}
        key={isLoggedIn && getUserId()}
      >
        <MainContainer show={isLoggedIn} locale={locale}>
          <Routes>
            <Route
              exct
              path="/reset-password/:token"
              element={<PassResetScreen />}
            />
            <Route
              exct
              path="/forgot-password"
              element={<ForgotPasswordScreen />}
            />
            {isLoggedIn && hasAccess(group, "leads") ? (
              <Route exct path="/main" element={<LeadScreen />} />
            ) : (
              <>
                <Route exct path="/main" element={<HomeScreen />} />
                {isLoggedIn && hasAccess(group, "partner") && (
                  <>
                    <Route
                      exct
                      path="/partner/:id"
                      element={<PartnerScreen />}
                    />
                    <Route
                      exct
                      path="/partner/new"
                      element={<PartnerScreen newEntry={true} />}
                    />
                    <Route
                      exct
                      path="/bussiness/:id"
                      element={<BussinessScreen />}
                    />
                    <Route
                      exct
                      path="/bussiness/new"
                      element={<BussinessScreen newEntry={true} />}
                    />
                    <Route
                      exct
                      path="/contract/:id"
                      element={<ContractScreen />}
                    />
                    <Route
                      exct
                      path="/contract/new/:clientid"
                      element={<ContractScreen newEntry={true} />}
                    />
                  </>
                )}
                <Route exct path="/profile" element={<ProfileScreen />} />
                {isLoggedIn && hasAccess(group, "invoices") && (
                  <>
                    <Route exct path="/invoices" element={<InvoicesPage />} />
                    {isLoggedIn && hasAccess(group, "invoices", "insert") && (
                      <Route
                        exct
                        path="/invoices/new"
                        element={<AddInvoiceScreen />}
                      />
                    )}
                    <Route
                      exct
                      path="/invoices/:id"
                      element={<InvoicePage />}
                    />
                  </>
                )}
                <Route exct path="/pdfs/:id" element={<PDFscreen />} />
                {/* <Route exct path="/task/new" element={<AddTaskScreen />} /> */}
                {isLoggedIn && hasAccess(group, "Admin") && (
                  <>
                    <Route exct path="/admin/:tab" element={<AdminScreen />} />
                    <Route exct path="/admin/emails/:id" element={<AdminManageEmail />} />

                    <Route
                      exct
                      path="/admin-reports/:tab"
                      element={<AdminReports />}
                    />
                  </>
                )}
                {isLoggedIn && hasAccess(group, "Admin") && (
                  <>
                    <Route
                      exct
                      path="/admin/api/:key"
                      element={<ApiScreen />}
                    />
                    <Route
                      exct
                      path="/admin/rights/:key"
                      element={<RightsGroupForm />}
                    />
                    <Route
                      exct
                      path="/admin/user/:key"
                      element={<AdminUserScreen />}
                    />
                    <Route
                      path="/template-docs/:tabValue"
                      element={<TemplateDocumentScreen />}
                    />
                  </>
                )}
                {isLoggedIn && hasAccess(group, "adminTasks") && (
                  <Route exct path="/admin-tasks" element={<AdminTasks />} />
                )}
                {isLoggedIn && hasAccess(group, "adminUsers") && (
                  <Route exct path="/admin-users" element={<AdminUsers />} />
                )}
                {isLoggedIn && hasAccess(group, "adminClients") && (
                  <Route
                    exct
                    path="/admin-clients"
                    element={<AdminClientScreen />}
                  />
                )}
              </>
            )}
            {isLoggedIn && (
              <>
                <Route exct path="/logout" element={<Logout />} />
                <Route exct path="/profile" element={<ProfileScreen />} />
              </>
            )}
            <Route
              path="/login"
              element={isLoggedIn ? <Navigate to="/main" /> : <LoginScreen />}
            />
            <Route path="/en" element={<LanguageSwitch />} />
            <Route path="/lv" element={<LanguageSwitch />} />
            <Route path="*" element={<RedirectWrapper />} />
          </Routes>
        </MainContainer>
      </WebPhoneProvider>
    </IntlProvider>
  );
}

export default App;
