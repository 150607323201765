/* global Phone */
import React, { createContext, useState, useEffect } from "react";
import { getCallToken, getUserId } from "./tokens";

const WebPhoneContext = createContext({
  phone: null,
  resetPhone: () => {},
});

// const ApiKeys = {
//   //1: "e656b3587cdadc0788d54e7456a3f34a63b124e56a72bc62b512722dcded384df5a78fad61af6e12bfab9a9bfbf59eb08b7f4385477874211ae0be5d9fd73220", //Oto
//   3: "063f150d34ba4d873785f59e9be9fd358177feeac0d7eaef1ec1fbe165c1b95f9ee723ce920f716b6303dbd85c4ecb03893382ac33e3e18f0217c6078c1a5711", //Renātei
//   5: "2e1410b8a25dac71a3fdf5b93c5fa3ef9967fee6ac7b1dd04599f452933abe406096eb1dfa657a550f9255e7f09d16ea6b2b9a7b2c4deec92f03dc01f3aa7882", //Kristers
//   6: "d10da79f54a6e413e8b109ca1d26bc260e1d05c710dccc228ef2f513018ab2c25418b7979c6bef2303e406009366b53746eff7a23401c3cdd38b2a597d1032be", //Laurai
// };

const WebPhoneProvider = ({ children, useProvider }) => {
  const [phone, setPhone] = useState(null);
  const key = getUserId();

  useEffect(() => {
    if (useProvider && key != null && key != false && key != "") {
      if (getCallToken() != "") {
        const script = document.createElement("script");
        script.src =
          "https://www.teltel.io/v2/static/webphone/latest/plugin/webphone.js";
        script.async = true;

        script.onload = () => {
          const phoneInstance = new Phone(getCallToken());
          setPhone(phoneInstance);
        };

        document.body.appendChild(script);
        return () => {
          document.body.removeChild(script);
        };
      }
    }
  }, []);

  const resetPhone = () => {
    if (phone) {
      // Clean up any resources related to the phone instance here
      // e.g., phone.disconnect() or similar, depending on the library's API
      setPhone(null);
    }
  };

  return useProvider ? (
    <WebPhoneContext.Provider value={{ phone, resetPhone }}>
      {children}
    </WebPhoneContext.Provider>
  ) : (
    children
  );
};

export { WebPhoneContext, WebPhoneProvider };
