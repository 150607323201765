import { useState } from "react";
import { Input, ActionIcon, useMantineTheme, Paper, Text } from "@mantine/core";
import { Search, X } from "tabler-icons-react";
import { getClients } from "../functions/api";
import { FormattedMessage, useIntl } from 'react-intl';

export default function SearchBox({ onClick }) {
  const intl = useIntl();
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const theme = useMantineTheme();

  const handleInputChange = (event) => {
    const inputText = event.target.value;
    setSearchText(inputText);
    if (inputText.length >= 3) {
      getClients(inputText)
        .then((data) => {
          setSearchResults(data);
        })
        .catch((error) => console.log(error));
      setIsOpen(true);
    } else {
      setSearchResults([]);
      setIsOpen(false);
    }
  };

  const handleListItemClick = (item) => {
    onClick(item);
    setSearchText(item?.c_name + ' ' + (item?.c_surname ? item?.c_surname : ""));
    setSearchResults([]);
    setIsOpen(false);
  };

  return (
    <div style={{ position: "relative" }}>
      <Input
        size="md"
        rightSection={
          <ActionIcon
            onClick={() => {
              setSearchText("");
              setSearchResults([]);
              setIsOpen(false);
            }}
          >
            {searchText ? <X size={18} /> : <Search size={18} />}
          </ActionIcon>
        }
        rightSectionWidth={42}
        value={searchText}
        onChange={handleInputChange}
        placeholder={useIntl().formatMessage({id: "search_client"})}
      />
      {isOpen && (
        <Paper
          style={{
            position: "absolute",
            top: "100%",
            left: 0,
            width: "100%",
            zIndex: 1,
            borderRadius: 4,
            overflow: "hidden",
          }}
          shadow="sm"
        >
          {searchResults && searchResults.length === 0 ? (
            <Text size="sm" textAlign="center" color="gray" padding="12px 16px">
              {intl.formatMessage({id: "not_found"})}
            </Text>
          ) : (
            <div style={{ maxHeight: 200, overflowY: "auto" }}>
              {searchResults && searchResults.length > 0 && searchResults.map((item) => (
                <div
                  key={item.id}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "8px 12px",
                    cursor: "pointer",
                    backgroundColor: item.time ? "#c4161630" : "#fff",
                  }}
                  onClick={() => handleListItemClick(item)}
                >
                  <Text size="sm">{item?.c_name} {" "}  {item?.c_surname}</Text>
                  <Text size="sm" color="gray" marginLeft={8}>
                    ({item?.c_email} {item?.c_phone} )
                  </Text>
                </div>
              ))}
            </div>
          )}
        </Paper>
      )}
    </div>
  );
}
