import { getToken, removeToken } from "./tokens";
// import { config } from "../../config";
// import {config} from 'config';
import { env } from "../env";

const url = env.BACKEND_URL;
//  const url = "https://bnd-inspira-crm-link-tmp.cloud/";
// const url = "http://localhost:3011/";
// const url = "https://sungo-backend.inspiralink.com/";
// const url = "http://54.90.69.154:3011/";
// const url = "https://erti-backend.inspiralink.com/";
//const url = "https://majaelpo-backend.inspiralink.com/";
//const url = "https://erti-backend.inspiralink.com/";

const handleResponse = async (response) => {
  if (response.status == 200) {
    return await response.json();
  } else if (response.status == 404) {
    return { error: "Not found" };
  } else if (response.status == 400) {
    return { error: "error" };
  } else if (response.status == 401) {
    window.location.href = "/logout";
  }
};

export const handleLogin = async (email, password, rememberMe) => {
  const response = await fetch(url + "auth/login", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password, rememberMe }),
  });
  return response;
};

export const SubmitComment = async (data) => {
  const token = getToken();
  return fetch(url + "clients/add-comment", {
    method: "POST",
    headers: { Authorization: `${token}`, "Content-Type": "application/json" },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const completeAndCreateTask = async (data) => {
  const token = getToken();
  return fetch(url + "task/complete-task", {
    method: "POST",
    headers: { Authorization: `${token}`, "Content-Type": "application/json" },
    body: JSON.stringify({ data }),
  }).then((response) => handleResponse(response));
};

export const closeTask = async (data) => {
  const token = getToken();
  return fetch(url + "task/close-task", {
    method: "POST",
    headers: { Authorization: `${token}`, "Content-Type": "application/json" },
    body: JSON.stringify({ data }),
  }).then((response) => handleResponse(response));
};

export const getTasks = async () => {
  const token = getToken();
  return fetch(url + "task/get-assigned", {
    headers: {
      Authorization: `${token}`,
    },
  }).then((response) => handleResponse(response));
};

export const getUser = async (search, uid) => {
  const token = getToken();
  const link = uid ? "user/get?uid=" + uid + "&q=null" : "user/get?q=" + search;
  console.log(link);
  return fetch(url + link, {
    headers: {
      Authorization: `${token}`,
    },
  }).then((response) => handleResponse(response));
};

export const getTaskById = async (id) => {
  const token = getToken();
  return fetch(url + "task/get", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify({ taskid: id }),
  }).then((response) => handleResponse(response));
};

export const getClients = async (search, hideResponsible) => {
  const token = getToken();
  return fetch(url + "clients/get-all", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify({ search, hideResponsible }),
  }).then((response) => handleResponse(response));
};

export const getClient = async (clientId, hideTasks) => {
  const token = getToken();
  const body = { clientid: clientId, hideTasks: hideTasks };
  return fetch(url + "clients/get", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(body),
  }).then((response) => handleResponse(response));
};

export const getClientSource = async (searchTerm) => {
  const token = getToken();
  return fetch(url + "clients/get-source", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify({ searchTerm }),
  }).then((response) => handleResponse(response));
};

export const createNewTask = async (data) => {
  const token = getToken();
  return fetch(url + "task/create", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const saveClient = async (data, newEntry) => {
  const token = getToken();
  return fetch(url + "clients/save", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify({ ...data, newEntry }),
  }).then((response) => handleResponse(response));
};

export const submitLead = async (data) => {
  const token = getToken();
  return fetch(url + "lead/save-lead", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const getAdminTasks = async (
  status,
  assignedTo,
  completedDateFrom,
  completedDateTo,
  deadlineFrom,
  deadlineTo,
  clientSearch,
  assignedToSearch
) => {
  const token = getToken();

  const data = {
    status,
    assignedTo,
    completedDateFrom,
    completedDateTo,
    deadlineFrom,
    deadlineTo,
    clientSearch,
    assignedToSearch,
  };

  return fetch(url + `admin/tasks`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const getUsers = async (
  search,
  status,
  access,
  group_id,
  sort_order,
  sort_column
) => {
  const token = getToken();
  const data = {
    search,
    status,
    access,
    group_id,
    sort_order,
    sort_column,
  };
  return fetch(url + `admin/users`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const createUser = async (data) => {
  const token = getToken();
  return fetch(url + `admin/create-user`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const updateUser = async (data) => {
  const token = getToken();
  return fetch(url + `admin/update-user`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const getBank = async (search) => {
  const token = getToken();
  return fetch(url + "admin/get-bank", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify({ search }),
  }).then((response) => handleResponse(response));
};

export const createInvoice = async (data) => {
  const token = getToken();
  return fetch(url + `invoices/create-invoice`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const getInvoices = async (data) => {
  const token = getToken();
  return fetch(url + `invoices/get-all`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const getInvoice = async (data) => {
  const token = getToken();
  return fetch(url + `invoices/get`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const updateInvoice = async (data) => {
  const token = getToken();
  return fetch(url + `invoices/update`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const getPinned = async () => {
  const token = getToken();
  return fetch(url + `pinn/get-all`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    // body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
  // .catch((e) => (window.location.href = "/logout"));
};

export const togglePin = async (data) => {
  const token = getToken();
  return fetch(url + `pinn/toggle`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const getPin = async (data) => {
  const token = getToken();
  return fetch(url + `pinn/get`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const checkResetToken = async (data) => {
  return fetch(url + `auth/reset-password-token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const getCauses = async (data) => {
  const token = getToken();
  return fetch(url + `clients/get-causes`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const triggerInvoiceSend = async (blob, id) => {
  const token = getToken();
  const formData = new FormData();
  formData.append("pdf", blob, id + "_invoice.pdf");
  formData.append("invoice_id", id);
  return fetch(url + "invoices/send-invoice", {
    method: "POST",
    headers: {
      Authorization: `${token}`,
    },
    body: formData,
  }).then((response) => handleResponse(response));
};

export const triggerInvoicePaid = async (id) => {
  const token = getToken();
  return fetch(url + "auth/invoices/" + id + "/pay", {
    method: "POST",
    headers: {
      Authorization: `${token}`,
    },
  }).then((response) => handleResponse(response));
};

export const triggerInvoiceVoid = async (id) => {
  const token = getToken();
  return fetch(url + "invoices/" + id + "/void", {
    method: "POST",
    headers: {
      Authorization: `${token}`,
    },
  }).then((response) => handleResponse(response));
};

export const getTaskCount = (id) => {
  const token = getToken();
  return fetch(url + `user/get-task-count`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify({ userid: id }),
  }).then((response) => handleResponse(response));
};

export const getReportFilters = () => {
  const token = getToken();
  return fetch(url + `report/get-filter`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  }).then((response) => handleResponse(response));
};

export const getReportClientResults = (data) => {
  const token = getToken();
  return fetch(url + `report/search-clients`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const getReportTaskResults = (data) => {
  const token = getToken();
  return fetch(url + `report/search-tasks`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const getReportInvoiceResults = (data) => {
  const token = getToken();
  return fetch(url + `report/search-invoices`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const taskMigration = (data) => {
  const token = getToken();
  return fetch(url + `user/migrate-tasks`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const getApis = (data) => {
  const token = getToken();
  return fetch(url + `api/v0x/get-api`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: data ? JSON.stringify({ apiid: data }) : null,
  }).then((response) => handleResponse(response));
};

export const updateApi = (data) => {
  const token = getToken();
  return fetch(url + `api/v0x/api-update`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const changePassword = (data) => {
  const token = getToken();
  return fetch(url + `user/change-password`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const getRightsGroups = () => {
  const token = getToken();
  return fetch(url + `rights/groups`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  }).then((response) => handleResponse(response));
};

export const getPasswordReset = (data) => {
  return fetch(url + `auth/rest-password-token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const setPasswordResetToken = (data) => {
  return fetch(url + `auth/forgot-password`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email: data }),
  }).then((response) => handleResponse(response));
};

export const setNewPassword = (data) => {
  return fetch(url + `auth/change-pass`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const saveNewSource = (data) => {
  const token = getToken();
  return fetch(url + `clients/save-source`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const getApiHistory = (data) => {
  const token = getToken();
  return fetch(url + `api/v0x/get-api-history`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const getMissedInvoices = async () => {
  const token = getToken();
  return fetch(url + `invoices/get-missed`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  }).then((response) => handleResponse(response));
};

export const getClientCalls = (data) => {
  const token = getToken();
  return fetch(url + `clients/get-client-calls`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const getReportCalls = (data) => {
  const token = getToken();
  return fetch(url + `report/search-calls`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const getPhoneNumbers = () => {
  const token = getToken();
  return fetch(url + `admin/get-numbers`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  }).then((response) => handleResponse(response));
};

export const getCredentials = () => {
  const token = getToken();
  return fetch(url + `admin/get-credentials`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  }).then((response) => handleResponse(response));
};

export const updatePhoneNumber = (data) => {
  const token = getToken();
  return fetch(url + `admin/update-number`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const getSystemNotif = async () => {
  const token = getToken();
  return fetch(url + "notif/get-system", {
    method: "POST",
    headers: {
      Authorization: `${token}`,
    },
  }).then((response) => handleResponse(response));
};

export const updateCredentials = (data) => {
  const token = getToken();
  return fetch(url + `admin/update-credentials`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const sendFile = (file, data) => {
  const token = getToken();
  const formData = new FormData();
  formData.append("file", file);
  formData.append("data", JSON.stringify(data));

  return fetch(url + "files/upload", {
    method: "POST",
    headers: {
      Authorization: `${token}`,
    },
    body: formData,
  }).then((response) => handleResponse(response));
};

export const getDocs = async (clientid) => {
  const token = getToken();
  return fetch(url + "files/get-documents", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(clientid),
  }).then((response) => handleResponse(response));
};

export const getReportLeadResults = (data) => {
  const token = getToken();
  return fetch(url + `report/search-leads`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const getUserAdmin = (data) => {
  const token = getToken();
  return fetch(url + `admin/get-user`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const createCause = (data) => {
  const token = getToken();
  return fetch(url + `clients/create-cause`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const getStateClients = () => {
  console.log("called");
  const token = getToken();
  return fetch(url + `clients/get-status-clients`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  }).then((response) => handleResponse(response));
};

export const updatePaidPadte = (data) => {
  console.log(data);
  const token = getToken();
  return fetch(url + `invoices/modify-paid-date`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const getHistory = async (id) => {
  const token = getToken();
  return fetch(url + "admin/get-history", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify({ last: id }),
  }).then((response) => handleResponse(response));
};

export const saveEmailAccount = async (data) => {
  const token = getToken();
  return fetch(url + "emails/create-email-account", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const updateEmailAccount = async (data) => {
  const token = getToken();
  return fetch(url + "emails/update-email-account", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const getEmailAccounts = async () => {
  const token = getToken();
  return fetch(url + "emails/get-email-accounts", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  }).then((response) => handleResponse(response));
};

export const getEmailAccount = async (id) => {
  const token = getToken();
  return fetch(url + "emails/get-email-account", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify({ id: id }),
  }).then((response) => handleResponse(response));
};

export const getLastLeads = async () => {
  const token = getToken();
  return fetch(url + "lead/get-last-leads", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  }).then((response) => handleResponse(response));
};
