import CryptoJS from "crypto-js";
import { FormattedMessage, useIntl } from 'react-intl';

export const parseNumberStringToArray = (numberString) => {
  const regex = /\[(\d+)\]/g; // Regular expression to match numbers in square brackets
  const matches = [];
  let match;

  while ((match = regex.exec(numberString)) !== null) {
    const number = parseInt(match[1], 10); // Parse the captured number
    matches.push(number);
  }

  return matches;
};

export function TimeDiffToDisplay(diff) {
  const seconds = Math.floor(Math.abs(diff) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const intl = useIntl();

  if (days > 0) {
    return `${days} ${intl.formatMessage({ id: "day" })}${days > 1 ? "s" : ""}`;
  } else if (hours > 0) {
    return `${hours} ${intl.formatMessage({ id: "hour" })}${hours > 1 ? "s" : ""}`;
  } else if (minutes > 0) {
    return `${minutes} ${intl.formatMessage({ id: "minute" })}${minutes > 1 ? "s" : ""}`;
  } else {
    return `${seconds} ${intl.formatMessage({ id: "second" })}${seconds > 1 ? "s" : ""}`;
  }
}

export const getInvoiceStart = (date) => {
  let formatted = date.replace("-", "");
  formatted = formatted.slice(0, 6);
  return formatted;
};

const ENCRYPTION_KEY = "your-32-byte-key-here-1234572311"; // Must be the same as in the backend

export function decryptData(text) {
  let textParts = text.split(":");
  let iv = CryptoJS.enc.Hex.parse(textParts.shift());
  let encryptedText = CryptoJS.enc.Hex.parse(textParts.join(":"));
  let cipherParams = CryptoJS.lib.CipherParams.create({
    ciphertext: encryptedText,
  });
  let key = CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY);
  let decrypted = CryptoJS.AES.decrypt(cipherParams, key, { iv: iv });

  return decrypted.toString(CryptoJS.enc.Utf8);
}

export async function encryptData(data, password) {
  const encoder = new TextEncoder();
  const encodedData = encoder.encode(data);
  const passwordBuffer = encoder.encode(password);
  const importedKey = await crypto.subtle.importKey(
    "raw",
    passwordBuffer,
    { name: "PBKDF2" },
    false,
    ["deriveKey"]
  );
  const salt = crypto.getRandomValues(new Uint8Array(16));
  const key = await crypto.subtle.deriveKey(
    {
      name: "PBKDF2",
      salt: salt,
      iterations: 100000,
      hash: "SHA-256",
    },
    importedKey,
    { name: "AES-GCM", length: 256 },
    false,
    ["encrypt", "decrypt"]
  );
  const iv = crypto.getRandomValues(new Uint8Array(12));
  const encryptedData = await crypto.subtle.encrypt(
    { name: "AES-GCM", iv: iv },
    key,
    encodedData
  );
  const encryptedDataArray = new Uint8Array(encryptedData);
  const combined = new Uint8Array(salt.length + iv.length + encryptedDataArray.length);
  combined.set(salt);
  combined.set(iv, salt.length);
  combined.set(encryptedDataArray, salt.length + iv.length);
  return btoa(String.fromCharCode.apply(null, combined));
}

