import { Grid, Switch, Title } from "@mantine/core";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Editor } from "primereact/editor";
import { useEffect, useState } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import AutoSelect from "../../Components/Inputs/AutoSelect";
import { getClient, getClients } from "../../functions/api";
import { useLocation } from "react-router-dom";
import {
  ClientBankInfo,
  ClientBasicData,
  ClientContactInfo,
} from "./PartnerScreen";
import EditableMulti from "../../Components/Inputs/EditableMulti";
import { Pinned } from "tabler-icons-react";
import PinButton from "../../Components/PinButton";
import { FormattedMessage, useIntl } from 'react-intl';

const ContractScreen = ({ newEntry = false }) => {
  const intl = useIntl();
  const [text, setText] = useState();
  const [safety, setSafety] = useState(false);
  const [modify, setModify] = useState(!newEntry);
  const [client, setClient] = useState();
  const [selectedSources, setSelectedSources] = useState([]);

  const location = useLocation();
  let routes = location.pathname.split("/");

  useEffect(() => {
    getClient(routes[3], false).then((res) => setClient(res.clientInfo));
  }, []);

  useEffect(() => {
    routes[2] != "new" &&
      newEntry == false &&
      client && client.sources &&
      setSelectedSources(client.sources);
  }, [client]);
  return (
    <div style={{ padding: 10 }}>
      <Grid>
        <Grid.Col xs={12}>
          {!newEntry && (
            <>
            <PinButton />
            <Switch
              style={{ float: "right", textAlign: "right" }}
              labelPosition="left"
              label={intl.formatMessage({id: "safety_lock"})}
              description={intl.formatMessage({id: "safety_lock_desc"})}
              color="teal"
              checked={safety}
              onChange={(event) => setSafety(event.currentTarget.checked)}
            />
            </>
          )}
        </Grid.Col>
        <Grid.Col xs={12}>
          <Accordion activeIndex={0}>
            <AccordionTab header={intl.formatMessage({id: "client_data"})}>
              <Grid>
                <Grid.Col xs={12}>
                  {/* <h3 style={{ fontSize: 22, fontWeight: "bold" }}>Klienta dati</h3> */}
                </Grid.Col>
                <Grid.Col xs={6}>
                  <ClientBasicData data={client} edit={false} />
                </Grid.Col>
                <Grid.Col xs={6}>
                  <ClientContactInfo data={client} edit={false} />
                  <ClientBankInfo data={client} edit={false} />
                </Grid.Col>
              </Grid>
            </AccordionTab>
          </Accordion>
        </Grid.Col>
        <Grid.Col xs={6}>
          <div className="card">
            <Accordion activeIndex={0}>
              <AccordionTab header={intl.formatMessage({id: "contract_text"})}>
                <Editor
                  value={text}
                  onTextChange={(e) => setText(e.htmlValue)}
                  style={{ height: "320px" }}
                />
              </AccordionTab>
            </Accordion>
          </div>
        </Grid.Col>
        <Grid.Col xs={6}>
          <div className="card">
            <Accordion activeIndex={0}>
              <AccordionTab header={intl.formatMessage({id: "contract_conditions"})}></AccordionTab>
            </Accordion>
          </div>
        </Grid.Col>
        <Grid.Col xs={6}>
          <div className="card">
            <Accordion activeIndex={0}>
              <AccordionTab header={intl.formatMessage({id: "contract_history"})}></AccordionTab>
            </Accordion>
          </div>
        </Grid.Col>
        <Grid.Col xs={6}>
          <div className="card">
            <Accordion activeIndex={0}>
              <AccordionTab header={intl.formatMessage({id: "payment_history"})}></AccordionTab>
            </Accordion>
          </div>
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default ContractScreen;
